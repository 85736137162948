import { useState, useEffect } from "react";
const useIsIOS = () => {
    const [isIosDevice, setIsIosDevice] = useState<boolean | null>(null);
    useEffect(() => {
        setIsIosDevice(
            ["iPad", "iPhone"].includes(navigator.platform) ||
                (navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document)
        );
    }, []);
    return isIosDevice;
};
export default useIsIOS;
