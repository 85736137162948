import React from "react";

const ButtonClickAnimation = ({children, disabled = false, disableBreathing = true,  ...rest}) => {
    if(disabled) return children
    return (
        <>
            <style jsx>{`
              div {
                transition: transform 50ms;
                animation: ${disableBreathing ? "none" : "breathing 3s ease-out infinite alternate-reverse"};
              }
              @media (hover: hover) {
                div:hover {
                  transform: scale(1.1);
                }
              }

              div:active {
                transform: scale(0.9);
              }

              @keyframes breathing {
                0% {
                  transform: scale(0.95);
                }

                25% {
                  transform: scale(1);
                }

                60% {
                  transform: scale(0.95);
                }

                100% {
                  transform: scale(1);
                }
              }

            `}</style>
            <div {...rest}>{children}</div>
        </>
    )
}

export default (ButtonClickAnimation)